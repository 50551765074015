import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 16px;
  -webkit-mask-image: linear-gradient(to bottom, black 5%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 5%, transparent 100%);

  & h1 {
    text-align: right;
  }
`;

export const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`;

export const CallsCell = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  text-align: center;
  padding: 4px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;

  @media(max-width: 1024px) {
    font-size: 12px;
  }
`;
