import { pauseQueue, unPauseQueue } from 'services/attendant';
import { setInPause } from 'store/call/actions';
import { setModalOpened } from 'store/modal/actions';
import { setUserStatus } from 'store/user/actions';
import { StatusConstants } from 'utils/constants';
import { UserStore } from '../../../store/user/index';

export const setAttendantPause = (pauseReason: string) => {
  try {
    pauseQueue(pauseReason);
    const status = UserStore.getRawState().status;

    if (status === StatusConstants.WAITING) {
      setUserStatus(StatusConstants.PAUSED);
    }

    setInPause(true);
  } catch {
    console.log("Não foi possível entrar em pausa. Operação falhou");
  }
}

export const removeAttendantPause = () => {
  try {
    unPauseQueue();
    setUserStatus(StatusConstants.WAITING);
    setInPause(false);
    setModalOpened(false);
  } catch {
    console.log("Não foi possível entrar em pausa. Operação falhou");
  }
}