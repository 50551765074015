import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { getLsUserToken } from "../localStorage";
import { getIpData } from "../utils";
import sweetAlert from "../utils/sweetAlert";

interface SocketContextData {
  addAttendantInSocketAndSetRamal: () => Promise<void>;
  connect: () => void;
  ramal: string | undefined;
  messageErrorLoginCaller: string | undefined;
  callWithoutTabbing: any;
  setCallWithoutTabbing: Function;
  receiveCalllEvent: any;
  preApproachTabbinStatus: any;
}

const SocketContext = createContext<SocketContextData>({} as SocketContextData);

export const SocketProvider: React.FC = ({ children }) => {
  const [ramal, setRamal] = useState<string>();
  const [messageErrorLoginCaller, setMessageErrorLoginCaller] =
    useState<string>();
  const [socket, setSocket] = useState<any>();
  const [callWithoutTabbing, setCallWithoutTabbing] = useState<any>();
  const [receiveCalllEvent, setReceiveCallEvent] = useState<any>();
  const [preApproachTabbinStatus, setPreApproachTabbinStatus] = useState<any>();

  const addAttendantInSocketAndSetRamal = async () => {
    socket.emit(
      "ADD_ATTENDANT_SOCKET_ID",
      {
        jwt: getLsUserToken(),
        ip: await getIpData(),
      },
      ({
        success,
        ramal: ramalUser,
        errors,
        callWithoutTabbing,
        preApproachTabbinStatus,
      }: {
        success: boolean;
        ramal: string;
        errors: Array<string>;
        callWithoutTabbing: any;
        preApproachTabbinStatus: any;
      }) => {
        if (success) {
          if (callWithoutTabbing) {
            sweetAlert.error("Você tem uma ligação sem tabulação");
            console.log("Call without tabbing", callWithoutTabbing);
            callWithoutTabbing.webhook = callWithoutTabbing.webhookCall;
            setCallWithoutTabbing(callWithoutTabbing);
            return callWithoutTabbing;
          }
          setRamal(ramalUser);
          setPreApproachTabbinStatus(
            preApproachTabbinStatus.map((status: any) => {
              status.preApproachTabbinStatus = true;
              return status;
            })
          );
        } else {
          setMessageErrorLoginCaller(errors.join(", "));
        }
      }
    );
  };

  const connect = () => {
    let url = window.location.href.indexOf("stage") !== -1
      ? process.env.REACT_APP_SOCKET_URL_STAGE
      : process.env.REACT_APP_SOCKET_URL;
    console.log(":::Socket Url:::", url);
    setSocket(
      io(url ?? "", {
        transports: ["websocket"],
        reconnection: true,
      })
    );
  };

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        addAttendantInSocketAndSetRamal();

        socket.on("NEW_CALL", (call: any) => {
          console.log("CALL RECEBIDA PRIMEIRAMENTE PELO SOCKET", call);
        });
      });
    }
  }, [socket]); //eslint-disable-line

  return (
    <SocketContext.Provider
      value={{
        preApproachTabbinStatus,
        receiveCalllEvent,
        connect,
        addAttendantInSocketAndSetRamal,
        setCallWithoutTabbing,
        ramal,
        messageErrorLoginCaller,
        callWithoutTabbing,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket(): SocketContextData {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
