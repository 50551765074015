import React from 'react';

import { approachCall, hangup } from './helper';
import { 
  ActiveBorder, 
  Container, 
  MessageIcon, 
  MenuCell, 
  Wrapper, 
  HangUpIco 
} from './styles';

const AttendanceBottomMenu: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <MenuCell active onClick={() => approachCall()}>
          <MessageIcon/>
          <p>Abordar</p>
          <ActiveBorder />
        </MenuCell>
        <MenuCell onClick={() => hangup()}>
          <HangUpIco />
          <p>Desligar</p>
        </MenuCell>
      </Container>
    </Wrapper>
  );
}

export default AttendanceBottomMenu;
