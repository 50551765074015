import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  display: flex;
  min-height: 100vh;
  background: #303030;
  color: #fff;
  justify-content: space-between;
  position: relative;
`;

export const LeftPanel = styled.div`
  height: 100vh;
  width: 75%;
`;

export const CoverImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`; 
