import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #00FFE6;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 2.25057px 60px #00FFE6, inset 0px -2.1434px 0.535851px rgba(255, 255, 255, 0.23);
  z-index: 1;

  & h1, h2 {
    margin: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  flex: 1;
  height: 100%;
  padding: 64px;
`;

export const AtiveLabel = styled.p`
  margin-bottom: 32px;
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  & svg {
    margin-right: 16px;
    position: relative;
  }

  & h1 {
    font-size: 40px;
    font-weight: 600;
  }

  & h2 {
    font-weight: 400;
    font-size: 25px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: end;
  margin-top: 16px;

  & svg {
    position: relative;
    bottom: 8px;
  }
`;

export const AdressCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;

  & h1 {
    font-weight: 600;
    font-size: 25px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const FieldsCell = styled.div`
  width: 100%;
  margin-top: 32px;

  & p {
    font-size: 12px;
    margin-bottom: 8px;
    opacity: 0.5;
  }

  & textarea {
    background: none;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    width: 70%;
    height: 100px;
    outline: none;
    padding: 4px;
    resize: none;
  }
`;
