import { SocketStore } from ".";

export const setSocket = (value: any) =>
  SocketStore.update(s => {
    s.socket = value;
  });

export const setCallWithoutTabbing = (value: any) =>
  SocketStore.update(s => {
    s.callWithoutTabbing = value;
  });

export const setActualCall = (value: any) =>
  SocketStore.update(s => {
    s.actualCall = value;
  });

export const setMessageErrorLoginCaller = (value: any) =>
  SocketStore.update(s => {
    s.messageErrorLoginCaller = value;
  });

export const setSocketRamal = (value: string) =>
  SocketStore.update(s => {
    s.ramal = value;
  });

export const setPreApproachTabbinStatus = (value: any) =>
  SocketStore.update(s => {
    s.preApproachTabbinStatus = value;
  });