import React, { useCallback, useMemo, useState } from 'react';
import { useCallHandler, useUser } from 'hooks';
import { Timer } from 'components/Timer';
import AudioSlider from 'components/AudioSlider';
import {ReactComponent as IcoWatch} from 'assets/icons/ico-watch.svg';
import {ReactComponent as IcoHeadphone} from 'assets/icons/ico-headphone.svg';
import {ReactComponent as IcoMicrophone} from 'assets/icons/ico-microphone.svg';

import { ActiveTime, AudioContainer, FinishedCallPhrase, SliderContainer, Wrapper } from './styles';
import { StatusConstants } from 'utils/constants';
import MicVolume from './MicVolume';
import { muteMic, unmuteMic } from './helper';

const ControlsContainer: React.FC = () => {
  const { status } = useUser(); 
  const { micMuted } = useCallHandler(); 
  const [volumeMuted, setVolumeMuted] = useState(false);

  const shouldStopTimer = useMemo(() => {
    if (status === StatusConstants.TABBING) {
      return true;
    }
    return false;
  }, [status]);

  const renderStatusPhrase = useCallback(() => {
    if (status === StatusConstants.TABBING) {
      return <FinishedCallPhrase>Encerrada</FinishedCallPhrase>
    }

    return <p>Falando...</p>
  }, [status]);

  const changeAudioVolume = (value: number) => {
    var mediaElement = document.getElementById(
      "sip-provider-audio"
    ) as HTMLAudioElement;

    mediaElement.volume = value / 100;

    if (value > 0) {
      setVolumeMuted(false);
    }
  }

  const handleMicMute = () => {
    if (micMuted) {
      unmuteMic();
    } else {
      muteMic();
    }
  }

  return (
    <Wrapper>
      <ActiveTime>
        <IcoWatch />
        <p><Timer paused={shouldStopTimer} isCallTiming/></p>
      </ActiveTime>
      {renderStatusPhrase()}
      <AudioContainer>
        <SliderContainer>
          <MicVolume />
          <IcoMicrophone
            onClick={() => handleMicMute()}
            style={{ opacity: micMuted ? 0.2 : 1 }}
          />
        </SliderContainer>
        <SliderContainer>
          <AudioSlider onChange={changeAudioVolume}/>
          <IcoHeadphone 
            onClick={() => setVolumeMuted(volumeMuted => !volumeMuted)}
            style={{ opacity: volumeMuted ? 0.2 : 1 }}
          />
        </SliderContainer>
      </AudioContainer>
    </Wrapper>
  );
}

export default ControlsContainer;
