import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { Loader } from "./components/Loader";
import { AuthProvider } from "./hooks/auth";
import { HeaderProvider } from "./hooks/headerHook";
import { SipJsProvider } from "./hooks/sipJsHook";
import { SocketProvider } from "./hooks/socketHook";
import { Store } from "./redux/store";

ReactDOM.render(
  <AuthProvider>
    <HeaderProvider>
      <SocketProvider>
        <SipJsProvider>
          <Provider store={Store}>
            <Loader />
            <App />
          </Provider>
        </SipJsProvider>
      </SocketProvider>
    </HeaderProvider>
  </AuthProvider>,
  document.getElementById("root")
);
