import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Registerer, UserAgent, UserAgentOptions } from "sip.js";
import { request } from "../services/request";
import { sleep, useStateRef } from "../utils";
import sweetAlert from "../utils/sweetAlert";
import { useSocket } from "./socketHook";

interface ConnectCredentials {
  ws: string;
  sipUri: string;
  password: string;
  realm: string;
  displayName: string;
}

enum headersEnum {
  "X-Queue" = "X-Queue",
  "X-Tronco" = "X-Tronco",
  "X-Idhistory" = "X-Idhistory",
  "X-Info" = "X-Info",
  "X-Callurl" = "X-Callurl",
  "X-Type" = "X-Type",
  "X-Numero" = "X-Numero",
  "Contact" = "Contact", 
}

interface SipJsContextData {
  connect: (crendials: ConnectCredentials) => void;
  hangup: () => void;
  setInCallData: (p: any) => void;
  muteMic: () => void;
  unmuteMic: () => void;
  handleRedial: () => void;
  setInCall: Function;
  connected: boolean;
  inCallData: any;
  inCall: boolean;
  micMuted: boolean;
  ramal: string;
  resetStates: () => void;
  setSipInPause: React.Dispatch<React.SetStateAction<boolean>>;
  sipData: any;
  setSipData: React.Dispatch<React.SetStateAction<undefined>>;
}

let path = "./bip.mp3";
let audio = new Audio(path);

const SipJsContext = createContext<SipJsContextData>({} as SipJsContextData);

export const SipJsProvider: React.FC = ({ children }) => {
  const [sipJsUa, setSipJsUa] = useState<UserAgent>();
  const [connected, setConnected] = useState<boolean>(false);
  const [inCall, setInCall, inCallRef] = useStateRef<boolean>(false);
  const [ramal, setRamal, ramalRef] = useStateRef<string>("");
  const [inCallData, setInCallData] = useState<any>();
  const [sipData, setSipData] = useState();
  const [sipInPause, setSipInPause] = useState(false);
  const [callSession, setCallSession, callSessionRef] = useStateRef<any>(null);
  const [micMuted, setMicMuted, micMutedRef] = useStateRef<boolean>(false);
  const { receiveCalllEvent } = useSocket();

  const resetStates = () => {
    setCallSession(null);
    setInCallData(null);
  };

  const getValueByHeader = (
    headers: { [key: string]: { raw: string; parsed: string }[] },
    header: string
  ) => {
    let find = headers[header];

    if (!find || find[0].raw === "") return null;

    return find[0].raw;
  };

  const getLeadInfosAndCreatCallRequest = async (session: any) => {
    console.log("Pegando os valores dos headers");

    if (!session.request) {
      let data: any = {
        sip: ramalRef.current,
        type: null,
        queue: null,
        phone: "Não listado",
        tronco: null,
        idhistory: null,
        info: null,
        callUrl: null,
      };

      setSipData(data);

      return;
    };

    let queue = getValueByHeader(
      session.request.headers,
      headersEnum["X-Queue"]
    );
    let tronco = getValueByHeader(
      session.request.headers,
      headersEnum["X-Tronco"]
    );
    let history = getValueByHeader(
      session.request.headers,
      headersEnum["X-Idhistory"]
    );
    let info = getValueByHeader(session.request.headers, headersEnum["X-Info"]);
    let callRecordUrl = getValueByHeader(
      session.request.headers,
      headersEnum["X-Callurl"]
    );
    let type = getValueByHeader(session.request.headers, headersEnum["X-Type"]);
    let phone = getValueByHeader(
      session.request.headers,
      headersEnum["X-Numero"]
    );

    let data: any = {
      sip: ramalRef.current,
      type,
      queue,
      phone,
      tronco,
      idhistory: history,
      info: JSON.parse(info || "[]"),
      callUrl: callRecordUrl,
    };
    
    if (!data.phone) {
      const contact = session.request.headers.From[0].parsed.uri.user;
      data.phone = contact;
    }

    setSipData(data);
  };

  //mount audio and generate call in db
  const setupRemoteMedia = async (session: any) => {
    getLeadInfosAndCreatCallRequest(session);
    console.log("montando o audio");
    if (!session) return;
    
    if (Object.keys(session).length === 0) {
      setInCall(false);

      return;
    }

    session?.stateChange?.addListener((event: any) => {
      if (event === "Terminated") {
        setInCall(false);
        setCallSession(null);
      }
    });

    if (!session?.sessionDescriptionHandler?.peerConnection?.getReceivers()) {
      setInCall(false);

      return;
    }

    console.log(`Headers Enviados: `, session.request.headers);
    console.log("Adicionando a voz no elemnto de audio");
    const remoteStream = new MediaStream();
    console.log(session);
    console.log(  
      session?.sessionDescriptionHandler?.peerConnection?.getReceivers()
    );
    session?.sessionDescriptionHandler?.peerConnection?.getReceivers()
      .forEach((receiver: any) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });

    var mediaElement = document.getElementById(
      "sip-provider-audio"
    ) as HTMLAudioElement;
    console.log(mediaElement);

    mediaElement.remove();

    var rootElement = document.getElementById("root") as HTMLAudioElement;
    mediaElement.srcObject = remoteStream;
    rootElement.appendChild(mediaElement);
    mediaElement.play();
  };

  const hangup = () => {
    try {
      console.log("Ligação desligada pelo atendente");
      callSessionRef.current.bye();
      setInCall(false);
    } catch (e) {
      console.log("Ligação desligada pelo atendente");
      setInCall(false);
    }
  };

  const muteMic = () => {
    console.log("Mutando o mic");
    let pc = callSessionRef.current.sessionDescriptionHandler.peerConnection;
    pc.getSenders().forEach((stream: any) => {
      stream.track.enabled = false;
    });
    setMicMuted(true);
  };

  const unmuteMic = () => {
    console.log("Desmutando o mic");
    let pc = callSessionRef.current.sessionDescriptionHandler.peerConnection;
    pc.getSenders().forEach((stream: any) => {
      stream.track.enabled = true;
    });
    setMicMuted(false);
  };

  const verifyPermissionsFunc = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (e) {
      sweetAlert.error(
        "Precisa liberar permissão de microfone no navegador, pode clicar na barra de endereço um pouco antes do http."
      );
      return false;
    }
  };

  const callReceivedBySocket = async (invite: any) => {
    try {
      console.debug("Socket do webhook acionado, aceitando a ligação");

      window.focus();
      audio.play();

      console.log(`Convite recebido`, invite);
      setInCall(true);
      setupRemoteMedia(invite);
    } catch (e) {
      console.error("Ocorreu algum erro ao montar a ligação", e);
      sweetAlert.error("Chamada sem dados. Entre em contato com o suporte");
    }
  };

  const connect = async ({
    sipUri,
    ws,
    password,
    realm,
    displayName,
  }: ConnectCredentials) => {
    try {
      let permission = await verifyPermissionsFunc();
      if (!permission) return;

      const uaConfig = {
        uri: UserAgent.makeURI(sipUri),
        transportOptions: {
          server: ws,
          traceSip: false,
          connectionTimeout: 3000,
        },
        displayName,
        authorizationUsername: displayName,
        authorizationPassword: password,
        stunServers: "stun:stun.l.google.com:19302",
        traceSip: true,
        contactParams: { transport: "wss" },
        delegate: {
          onInvite: (invite: any) => {
            console.log(
              "Convite recebido, aguardando webhook fazer a chamada ao socket: ",
              invite
            );
            if (inCallRef.current || sipInPause) {
              console.log("Em ligação/pause, convite rejeitado");
              invite.reject();
              return;
            }
            console.log("Setando a session da ligação e aceitando o convite");
            invite
              .accept()
              .then(() => { 
                console.log("convite aceito");
                callReceivedBySocket(invite);
              })
              .catch((e: any) => console.log("erro ao aceitar convite", e));
            setCallSession(invite);
          },
        },
      };

      // SimpleUser construction
      const ua = new UserAgent(uaConfig);
      let register = new Registerer(ua);
      setSipJsUa(ua);
      await ua.start();
      await register.register();

      register.stateChange.addListener((event) => {
        if (event === "Registered") {
          setConnected(true);
          setRamal(displayName);
        }
      });

      ua.stateChange.addListener((event) => {
        console.log(event);
      });
    } catch (e: any) {
      console.log(`Erro ao conectar no sip: ${e.message}`);
      sweetAlert.error(
        "Ocorreu algum erro ao se conectar ao servidor do discador"
      );
    }
  };

  const handleRedial = async () => {
    console.log("Rediscando... Chamando função callReceivedBySocket na mão");
    await sleep(1000);
    callReceivedBySocket({});
  };

  // useEffect(() => {
  //   if (!receiveCalllEvent) return;
  //   callReceivedBySocket({});
  // }, [receiveCalllEvent]);

  return (
    <Fragment>
      <SipJsContext.Provider
        value={{
          handleRedial,
          ramal,
          connect,
          hangup,
          connected,
          inCallData,
          inCall,
          setInCallData,
          setInCall,
          micMuted,
          muteMic,
          unmuteMic,
          resetStates,
          setSipInPause,
          sipData,
          setSipData,
        }}
      >
        <audio id="sip-provider-audio" autoPlay={true} />
        {children}
      </SipJsContext.Provider>
    </Fragment>
  );
};

export function useSipJs(): SipJsContextData {
  const context = useContext(SipJsContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
