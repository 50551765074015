import { ModalStore } from ".";

export const setModalChildren = (value: React.ReactNode) =>
  ModalStore.update(s => {
    s.children = value;
  });

export const setModalOpened = (value: boolean) =>
  ModalStore.update(s => {
    s.opened = value;
  });

export const setModalWidth = (value: string) =>
  ModalStore.update(s => {
    s.width = value;
  });