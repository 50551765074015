import styled from "styled-components";

export const Wrapper = styled.div`
  font-weight: 400;
  justify-content: flex-end;

  & h4 {
    font-weight: 400;
    text-align: right;
    margin-bottom: 8px;
  }
`;

export const StatusPhrase = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  & h1 {
    color: ${props => props.isActive ? '#00FFE6' : '#F80000'};
    margin-right: 8px;
  }

  & svg {
    & path {
      fill: ${props => props.isActive ? '#00FFE6' : '#F80000'};
    }
  }
`;

export const TimersContainer = styled.div`
  -webkit-mask-image: linear-gradient(to bottom, black 5%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 5%, transparent 100%);
`;

export const TimersCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & p {
    margin-right: 4px;
  }
`;
