import { NewButton as NewButtonStyle } from "./styles";

interface INewButton {
  text: string;
  onClick?: Function;
  style?: any;
}

export const PrimaryButton: React.FC<INewButton> = ({
  text,
  onClick,
  style,
  ...props
}) => {
  return (
    <NewButtonStyle
      onClick={() => onClick && onClick()}
      style={style ? style : {}}
    >
      {text}
    </NewButtonStyle>
  );
};
