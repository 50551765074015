import Dialer from "pages/Dialer";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getPayload } from "utils";
import { Dial } from "../pages/Dial";
import { Login } from "../pages/Login";
import { PrivateRoute } from "./PrivateRoute";

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact render={() => (
      getPayload()?.userType?.name === "Atendente" 
      ? (<Redirect to="/discador" />) 
      : (<Login />)
    )}/>

    <Route path="/sistema" exact component={Dialer} />
    <PrivateRoute path="/" exact component={Dial} />
    <PrivateRoute path="/discador" exact component={Dial} />
  </Switch>
);
