import styled from "styled-components";
import {ReactComponent as Logo} from "assets/images/pessoalizeLogoPurple.svg";

export const Wrapper = styled.div`
  width: 75%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const PresentationPhrase = styled.h1`
   font-size: 40px;
    margin-left: 64px;

    & span {
    font-weight: bold;
  }
`;

export const LogoComponent = styled(Logo)`
  transform: scale(0.5);
  transform-origin: 0 0;
  position: absolute;
  top: 32px;
  left: 64px;

  & path {
    fill: white;
  }
`;
