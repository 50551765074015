import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  backdrop-filter: blur(15px);
  height: 100vh;
  width: 25%;
  padding: 0  32px;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
`;

export const ClockContainer = styled.div`
  font-weight: bold;
  font-size: 88px;
  text-align: center;

  @media(max-width: 1024px) {
    font-size: 48px;
  }
`;
