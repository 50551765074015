import React, { useState, useEffect } from 'react';
import { useUser } from 'hooks';
import dayjs from 'dayjs';
import { StatusConstants } from 'utils/constants';
import { Timer } from 'components/Timer';
import { setActualModal } from 'utils/popups';
import SpecialPauseButton from './SpecialPauseButton';
import RemovePauseModal from './RemovePauseModal';

import { ActionButton,
         IconPause,
         IconPlay,
         TimeContainer,
         Wrapper } from './styles';

const PlayContainer: React.FC = () => {
  const { status } = useUser();
  const [clickedPause, setClickedPause] = useState(false);
  const [loadedTimer, setLoadedTimer] = useState(false);

  useEffect(() => {
    if (!dayjs().isSame(localStorage.getItem("@actualDate"), "day")) {
      localStorage.setItem("@activeTime", "00:00");
      localStorage.setItem("@actualDate", `${dayjs()}`);
    }

    setLoadedTimer(true);
  }, []);

  const renderActionButton = () => {
    if (clickedPause) {
      return (<SpecialPauseButton setClickedPause={setClickedPause} />);
    }

    if (status === StatusConstants.INACTIVE || status === StatusConstants.PAUSED) {
      return (
        <ActionButton onClick={() => {
          setActualModal(<RemovePauseModal />, true, 'auto');
        }}>
          <IconPlay />
        </ActionButton>
      )
    }

    return (
      <ActionButton onClick={() => {
        setClickedPause(true);
      }}>
        <IconPause />
      </ActionButton>
    )
  };

  return (
    <Wrapper>
      <TimeContainer>
        <h1>Tempo Ativo</h1>
        <h2>
          {loadedTimer && <Timer
            paused={
              status === StatusConstants.INACTIVE ||
              status === StatusConstants.PAUSED ||
              status === StatusConstants.TABBING ||
              status === StatusConstants.ERROR
            }
            value={localStorage.getItem("@activeTime") || ""}
            onSetTimer={(time) => localStorage.setItem("@activeTime", time)}
          />}
        </h2>
      </TimeContainer>

      {renderActionButton()}
    </Wrapper>
  );
}

export default PlayContainer;
