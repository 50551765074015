import React from 'react';
import { ButtonErrorIcon, ButtonIcon, ButtonLoader, ButtonSuccessIcon, InputButton } from './styles';

interface IProps {
  isFormError: boolean;
  isFormSucess: boolean;
  isEntering: boolean;
}

const FormButtons: React.FC<IProps> = ({ isFormError, isFormSucess, isEntering }: IProps) => {
  if (isFormError) {
    return (
      <ButtonIcon>
        <ButtonErrorIcon />
      </ButtonIcon>
    )
  }

  if (isFormSucess) {
    return (
      <ButtonIcon>
        <ButtonSuccessIcon />
      </ButtonIcon>
    )
  }

  if (isEntering) {
    return (
      <ButtonLoader /> 
    )
  } 

  return (
    <InputButton type="submit">Entrar</InputButton>
  )
}

export default FormButtons;