import { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";

interface IClock {
  mask: boolean;
}
export const Clock = (mask: IClock) => {
  const [timer, setTimer] = useState<string>();
  const [timeClock, setTimeClock] = useState<string>();

  const startClock = useCallback(() => {
    const today = dayjs();
    const update = mask ? today.format("HH:mm") : today.format("HH:mm:ss");
    setTimeClock(today.format("HH:mm:ss"));
    setTimer(update);
  }, [setTimeClock, mask]);

  useEffect(() => {
    setTimer(mask ? dayjs().format("HH:mm") : dayjs().format("HH:mm:ss"));
  }, [mask]);

  useEffect(() => {
    const delay = setTimeout(() => {
      startClock();
    }, 1000);
    return () => clearTimeout(delay);
  }, [timeClock, startClock]);

  return <>{timer}</>;
};
