import { request } from "services/request";
import IUserUseWebSoftphone from "interfaces/IUserUseWebSoftphone";
import { ICall } from "interfaces/ICall";
import ISchedulingDate from "interfaces/ISchedulingDate";
import IStatusUser from "interfaces/IStatusUser";

const verifyUserUseWebSoftphone = async (): Promise<IUserUseWebSoftphone> => {
  return await request({
    method: "GET",
    path: `caller/verify-user-use-websoftphone`,
    showSuccessMessage: false,
    showLoading: false,
  });
}

const getLastCalls = async (): Promise<ICall[]> => {
  return await request({
    method: "GET",
    path: `get-latest-calls`,
    showSuccessMessage: false,
    showLoading: false,
  });
}

const pauseQueue = async (status: string): Promise<any> => {
  return await request({
    method: "POST",
    path: `set-pause`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
    data: {
      pause: status,
    },
  });
}

const unPauseQueue = async (): Promise<any> => {
  return await request({
    method: "POST",
    path: `remove-pause`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
    data: {},
  });
}

const getPauseStatus = async (): Promise<any> => {
  return await request({
    method: "GET",
    path: `attendant-pauses`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false
  });
}

const getUserStatus = async (): Promise<IStatusUser[]> => {
  return await request({
    method: "GET",
    path: `get-status-user`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  })
}

const logoffAttendant = async (): Promise<any> => {
  return await request({
    method: "POST",
    path: `logout`,
    showSuccessMessage: false,
    showErrorMessage: false,
  });
}

const saveTabbing = async (
  tabbingStatus: any,
  inCallData?: ICall,
  observations?: string,
  ): Promise<any> => {

  return await request({
    method: "POST",
    path: `${inCallData?._id}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: false,
      retry: false,
      note: observations
    },
    showSuccessMessage: false,
  });
}

const makeRedialCall = async (
  tabbingStatus: any,
  inCallData?: ICall,
  observations?: string,
  ): Promise<any> => {

  return await request({
    method: "POST",
    path: `${inCallData?._id}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: false,
      retry: true,
      note: observations
    },
    showSuccessMessage: false,
  });
}

const makeSchedulingCall = async (
  tabbingStatus: any,
  time: ISchedulingDate,
  inCallData?: ICall,
  observations?: string,
  ): Promise<any> => {

  return await request({
    method: "POST",
    path: `${inCallData?._id}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: time,
      retry: true,
      note: observations
    },
    showSuccessMessage: false,
  });
}

const getSchedules = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `get-schedule-date`,
    showSuccessMessage: false,
    showErrorMessage: false,
    showLoading: false,
  });
}

const approach = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `approach`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const getLastCallData = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `get-call-data`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const loginAsterisk = (): Promise<any> => {
  return request({
    method: "POST",
    path: `login-asterisk`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const getCallWithoutTabbing = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `get-info-calls-without-tabbing`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const getPreTabbingStatus = (): Promise<string[]> => {
  return request({
    method: "GET",
    path: `get-pre-tabbing-status`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const postponeCall = (callData: ICall): Promise<any> => {
  return request({
    method: "POST",
    path: `postpone-call`,
    data: {
      callId: callData._id,
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showLoading: false,
  });;
}

export {
  verifyUserUseWebSoftphone,
  getLastCalls,
  pauseQueue,
  unPauseQueue,
  getPauseStatus,
  getUserStatus,
  logoffAttendant,
  saveTabbing,
  makeRedialCall,
  makeSchedulingCall,
  getSchedules,
  approach,
  loginAsterisk,
  getCallWithoutTabbing,
  getPreTabbingStatus,
  getLastCallData,
  postponeCall,
}
