import React, { useEffect } from 'react';
import LeftPanel from 'components/LeftPanel';
import RightPanel from 'components/RightPanel';
import { calculateActualCoverImage } from 'utils';
import { useUser } from 'hooks';
import { CoverImage, Wrapper } from './styles';
import { preConectTabbingStatus, preConnectAsterisk, verifyCallWithoutTabbing, verifyScheduling } from './helper';
import Modal from 'components/Modal';

const Dialer: React.FC = () => {
  const { status } = useUser()
  const initialConnection = async () => {
    await preConnectAsterisk(status);
    await preConectTabbingStatus();

    const callWithoutTabbing = await verifyCallWithoutTabbing();

    if (!callWithoutTabbing) {
      await verifyScheduling();
    }
  }

  useEffect(() => {
    initialConnection();
  }, []);

  return (
    <Wrapper>
      <CoverImage src={calculateActualCoverImage()} />
      <LeftPanel />
      <RightPanel />
      <Modal />
    </Wrapper>
  );
}

export default Dialer;
