import React, { FormEvent, useState } from 'react';
import { verifyPassword } from "services/login";
import { removeAttendantPause } from '../helper';

import { CustomizedButton, CustomizedInput, Wrapper } from './styles';

const RemovePauseModal: React.FC = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleForm = async (event: FormEvent) => {
    event.preventDefault();

    if (!password.trim()) {
      setErrorMessage("Campo de senha vazio!")
      return;
    }

    try {
      await verifyPassword(password.trim());
      removeAttendantPause();
    } catch {
      setErrorMessage("Senha inválida!")
    }
  }

  return (
    <Wrapper>
      <form onSubmit={handleForm}>
        <h3>Digite a sua senha para remover a pausa:</h3>
        <CustomizedInput type="password" onChange={(event) => setPassword(event.target.value)} />
        { errorMessage !== "" && <p>{errorMessage}</p>}
        <CustomizedButton type='submit'>Remover pausa</CustomizedButton>
      </form>
    </Wrapper>
  );
}

export default RemovePauseModal;