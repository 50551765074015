import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #F80000;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 2.25057px 60px #F80000, inset 0px -2.1434px 0.535851px rgba(255, 255, 255, 0.23);
  z-index: 1;
  overflow-y: auto;

  & h1, h2 {
    margin: 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  flex: 1;
  padding: 64px;
`;

export const AtiveLabel = styled.p`
  margin-bottom: 32px;
`;

export const InformationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  & svg {
    margin-right: 16px;
    position: relative;
  }

  & h1 {
    font-size: 40px;
    font-weight: 600;
  }

  & h2 {
    font-weight: 400;
    font-size: 25px;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 16px;
  width: 50%;
`;

export const InputContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 36px;

  & p {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 8px;
    font-size: 14px;
  }

  & textarea {
    width: 100%;
    height: 100px;
    background: none;
    border: 1px solid #fff; 
    border-radius: 10px;
    resize: none;
    color: #fff;
    padding: 8px;
  }
`;
