import { getLastCalls, getPauseStatus, getUserStatus } from "services/attendant";
import { setLastCalls, setLastStatus, setPauseStatus } from "store/user/actions";

export const updateLastCalls = async () => {
  const lastCallsResponse = await getLastCalls();
  setLastCalls(lastCallsResponse);
}

export const updatePauseStatus = async () => {
  const pauseStatusResponse = await getPauseStatus();
  setPauseStatus(pauseStatusResponse);
}

export const updateLastStatus = async () => {
  const userStatus = await getUserStatus();
  setLastStatus(userStatus);
}