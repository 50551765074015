import React, { Dispatch, SetStateAction, useState } from 'react';
import { ButtonEyeClosed, 
         ButtonEyeOpen, 
         ButtonIconEye, 
         InputFormPassword, 
         InputFormPasswordWrapper } from './styles';

interface IProps {
  isEntering: boolean;
  onChange: Dispatch<SetStateAction<string>>
  placeholder: string;
}

const PasswordField = ({ isEntering, onChange, placeholder }: IProps) => {
  const [showingPassword, setShowingPassword] = useState(false);

  return (
    <InputFormPasswordWrapper isEntering={isEntering}>
      <InputFormPassword 
        type={showingPassword ? "text" : "password"} 
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder} />

      {!showingPassword 
      ? <ButtonIconEye 
          type="button"
          tabIndex={-1}
          onClick={() => setShowingPassword(showing => !showing)}>
          <ButtonEyeOpen />
        </ButtonIconEye> 
      : <ButtonIconEye 
          type="button" 
          tabIndex={-1}
          onClick={() => setShowingPassword(showing => !showing)}>
          <ButtonEyeClosed/>
        </ButtonIconEye>}
    </InputFormPasswordWrapper>
  );
}

export default PasswordField;
