import { ICall } from "interfaces/ICall"
import ISchedulingDate from "interfaces/ISchedulingDate";
import { getLastCallData, getSchedules, makeRedialCall, makeSchedulingCall, saveTabbing, unPauseQueue } from "services/attendant"
import { SocketStore } from "store/socket";
import { UserStore } from "store/user";
import { setUserStatus } from "store/user/actions";
import { callReceivedBySocket } from "utils/asterisk";
import { StatusConstants } from "utils/constants";
import sweetAlert from "utils/sweetAlert";
import { setIsOnPauseQueue } from 'store/user/actions';
import { setAttendantPause } from "components/RightPanel/PlayContainer/helper";
import { setApproached, setInPause } from "store/call/actions";
import { schedulingPopup } from "utils/popups";
import { setActualCall, setCallWithoutTabbing } from "store/socket/actions,";

interface IFinalizeCallTab {
  tabbingStatus: string;
  callData?: ICall;
  observations: string;
}

export const finalizeCallTab = async ({
  tabbingStatus,
  observations
  } : IFinalizeCallTab) => {
  try {
    let callData = SocketStore.getRawState().actualCall;

    if (!callData) {
      callData = await getLastCallData();
    }

    await saveTabbing(tabbingStatus, callData, observations);

    if (!!SocketStore.getRawState().callWithoutTabbing) {
      window.location.reload();
      return;
    }

    const pauseQueue = UserStore.getRawState().pauseQueue;

    if (pauseQueue.isOnPauseQueue) {
      setInPause(true);
      setAttendantPause(pauseQueue.pauseReason);
      setUserStatus(StatusConstants.PAUSED);
      setIsOnPauseQueue(false, "");

      return;
    }

    const schedulingResponse = await getSchedules();

    if (!!schedulingResponse.client) {
      schedulingPopup(schedulingResponse);
    } else {
      setUserStatus(StatusConstants.WAITING);
      unPauseQueue();
    }

    setApproached(false);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);
  } catch {
    sweetAlert.error("Ocorreu um erro ao tabular");
  }
}

export const redialCallTab = async (observations: string) => {
  try {
    const tabbingStatus = {
      label: 'Rediscar',
      status: 'Rediscar',
      type: 'rediscar',
    }

    let callData = SocketStore.getRawState().actualCall;

    if (!callData) {
      callData = await getLastCallData();
    }

    await makeRedialCall(tabbingStatus, callData, observations);
    callReceivedBySocket(callData);
    setUserStatus(StatusConstants.ATTENDANCE);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);
  } catch {
    sweetAlert.error("Ocorreu um erro ao rediscar");
  }
}

export const schedulingCallTab = async (
  schedulingDate: ISchedulingDate,
  observations: string,
  tabbingStatusSelected: any,
  ) => {
  try {
    const tabbingStatus = {
      label: tabbingStatusSelected?.label,
      status: tabbingStatusSelected?.status,
      type: tabbingStatusSelected?.type,
    }

    if (!tabbingStatusSelected.label || !tabbingStatusSelected.status) {
      tabbingStatus.label = "Agendamento Pessoal";
      tabbingStatus.status = "Agendamento Pessoal";
    }

    if (!tabbingStatus.type) {
      tabbingStatus.type = "ag-pessoal";
    }

    const callData = SocketStore.getRawState().actualCall;

    await makeSchedulingCall(tabbingStatus, schedulingDate, callData, observations);

    if (!!SocketStore.getRawState().callWithoutTabbing) {
      window.location.reload();
      return;
    }

    const pauseQueue = UserStore.getRawState().pauseQueue;

    if (pauseQueue.isOnPauseQueue) {
      setUserStatus(StatusConstants.PAUSED);
      setAttendantPause(pauseQueue.pauseReason);
      setIsOnPauseQueue(false, "");
      setInPause(true);

      return;
    }

    const schedulingResponse = await getSchedules();

    if (!!schedulingResponse.client) {
      schedulingPopup(schedulingResponse);
    } else {
      setUserStatus(StatusConstants.WAITING);
      unPauseQueue();
    }

    setApproached(false);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);
  } catch {
    sweetAlert.error("Ocorreu um erro ao agendar");
  }
}
