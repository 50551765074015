import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface IProps {
  onChange?: (value: number) => void;
}

const AudioSlider: React.FC<IProps> = ({ onChange }: IProps) => {
  return (
    <Slider 
      railStyle={{ backgroundColor: '#373737', height: 8, top: 1.5 }}
      trackStyle={{ backgroundColor: '#00FFE6', height: 8, top: 1.5 }}
      handleStyle={{
        borderColor: '#00FFE6',
        height: 8,
        width: 8,
        marginRight: -2,
        marginTop: 0,
        top: 1.5,
        backgroundColor: '#00FFE6',
        opacity: 1,
      }}
      reverse
      onChange={(value) => {
        if (!!onChange && !Array.isArray(value)) {
          onChange(value);
        }
      }}
      defaultValue={90}
    />
  );
}

export default AudioSlider;
