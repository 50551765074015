import React, { useState } from 'react';
import { useUser } from 'hooks';
import { setLocalStorage } from 'utils';
import { setNotes, setShowingNotes } from 'store/user/actions';

import {ReactComponent as IcoCheck} from "assets/icons/ico-check.svg";
import {ReactComponent as IcoCancel} from "assets/icons/ico-cancel.svg";

import { ActionButton, ActionContainer, Container, Wrapper } from './styles';

const AnotationContainer: React.FC = () => {
  const { notes } = useUser();  
  const [backupNotes] = useState(notes);
  const [myNotes, setMyNotes] = useState(notes);

  return (
    <Wrapper>
      <h1>Minhas anotações</h1>
      <Container>
        <textarea placeholder="Escreva aqui" 
          value={myNotes}
          onChange={(event) => {
            setNotes(event.target.value);
            setMyNotes(event.target.value);
          }}/>

        <ActionContainer>
          <ActionButton onClick={() => {
              setNotes(backupNotes);
              setShowingNotes(false)
            }}>
            <IcoCancel />
          </ActionButton>
          <ActionButton onClick={() => {
            setLocalStorage("@notes", notes);
            setShowingNotes(false);
            }}>
            <IcoCheck />
          </ActionButton>
        </ActionContainer>
      </Container>  
    </Wrapper>
  );
}

export default AnotationContainer;
