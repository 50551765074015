import styled, { keyframes } from "styled-components";
import { ReactComponent as ErrorButtonIcon } from "assets/icons/cross.svg";
import { ReactComponent as SuccessButonIcon } from "assets/icons/check.svg";
import { ReactComponent as Loader } from "assets/icons/loader.svg";

const pop = keyframes`
  50%  {transform: scale(1);}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg) scale(0.75);
  }
  to {
    transform: rotate(360deg) scale(0.75);
  }
`;

export const InputButton = styled.button`
  cursor: pointer;
  background: #1f0533;
  width: 30%;
  height: 40px;
  color: white;
  font-weight: bold;
  border-radius: 30px;
  padding: 0 0;
  margin-top: 32px;
  transition: 0.5s;
  border: none; 

  &:hover {
    background: #00ffe6;
    color: #1d113b;
    border: none; 
  }
`;

export const ButtonIcon = styled.button`
  cursor: pointer;
  background: #1f0533;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 32px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonErrorIcon = styled(ErrorButtonIcon)`
  transform: scale(0.75);
  animation: ${pop} 0.4s linear 1;
`;

export const ButtonSuccessIcon = styled(SuccessButonIcon)`
  transform: scale(0.75);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${pop} 0.4s linear 1;
`;

export const ButtonLoader = styled(Loader)`
  animation: ${rotate} 1s ease-in-out infinite;
  transform-origin: 28.5px 29px;
  margin-top: 32px;
  width: 57px;
  height: 65px;
  margin-bottom: 2px;
  margin-top: 22px;
`;