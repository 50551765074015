import styled from "styled-components";
import {ReactComponent as IcoHouse} from "assets/icons/ico-house.svg";
import {ReactComponent as IcoChart} from "assets/icons/ico-chart.svg";
import {ReactComponent as IcoSuggestion} from "assets/icons/ico-suggestion.svg";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px 25px 0px 0px; 
  padding: 24px 48px 0 48px;
`;

export const MenuCell = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  width: 20%;

  & p {
    margin-top: 8px;
    font-size: 12px;
  }
`;

export const ActiveBorder = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 60px;
  background: #00FFE6;
`;

export const HouseIcon = styled(IcoHouse)``;

export const ChartIcon = styled(IcoChart)``;

export const SuggestionIcon = styled(IcoSuggestion)``;
