import React from 'react';
import AnotationContainer from '../AnotationContainer';
import ControlsContainer from '../ControlsContainer';
import { Separator } from '../LastAnotations/styles';
import ProfileContainer from '../ProfileContainer';

const AttendanceSection: React.FC = () => {
  return (
    <>
      <ProfileContainer showClock />
      <ControlsContainer />
      <Separator />
      <AnotationContainer />
    </>
  );
}

export default AttendanceSection;
