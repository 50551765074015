import { CallStore } from "store/call";
import { setMicMuted } from "store/call/actions";

export const modifyGain = (stream: MediaStream, gainValue: number) => {
  var audioTrack = stream.getAudioTracks()[0];
  var ctx = new AudioContext();
  var src = ctx.createMediaStreamSource(new MediaStream([audioTrack]));
  var dst = ctx.createMediaStreamDestination();
  var gainNode = ctx.createGain();
  gainNode.gain.value = gainValue;
  src.connect(gainNode);
  gainNode.connect(dst);  
  stream.removeTrack(audioTrack);
  stream.addTrack(dst.stream.getAudioTracks()[0]);
}

export const muteMic = () => {
  const callSession = CallStore.getRawState().callSession;
  let perConections = callSession?.sessionDescriptionHandler?.peerConnection;
  perConections?.getSenders().forEach((stream: any) => {
    stream.track.enabled = false;
  });
  setMicMuted(true);
}

export const unmuteMic = () => {
  const callSession = CallStore.getRawState().callSession;
  let perConections = callSession?.sessionDescriptionHandler?.peerConnection;
  perConections?.getSenders().forEach((stream: any) => {
    stream.track.enabled = true;
  });
  setMicMuted(false);
}
