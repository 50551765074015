import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as Logo } from "assets/images/pessoalizeLogoPurple.svg";

export const AuthWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #1f0533;
  font-family: "Poppins", sans-serif;
`;

export const ClockContainer = styled.div`
  color: white;
  font-size: 32px;
  position: absolute;
  top: 8px;
`;

export const ErrorContainer = styled(motion.div)<{ error: string }>`
  position: absolute;
  width: 40%;
  min-height: 40%;
  background: ${props => JSON.parse(props.error) ? '#ff0062' : '#00ffe6'};
  border-radius: 30px;
`;

export const AuthContainer = styled.div`
  z-index: 1;
  width: 40%;
  min-height: 40%;
  padding: 16px 0;
  height: auto;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
`

export const FormLabel = styled.p<{ authStatus: string, isEntering: boolean }>`
  color: #747474;
  margin-top: 32px;

  ${props => props.authStatus === "Error" && css`
    color: #ff0062;
  `}

  ${props => props.authStatus === "Success" && css`
    color: #00ffe6;
  `}

  opacity: ${props => props.isEntering ? "0" : "1"};
`;

export const LogoImage = styled.img`
  width: auto;
  height: 200px;
`;

export const AuthForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  & p {
    position: absolute;
    top: 20%;
  }
`;

export const InputForm = styled.input<{isEntering: boolean}>`
  width: 60%;
  border-radius: 30px;
  border: 1px solid #b1b1b1;
  text-align: center;
  padding: 8px 0;
  margin-top: 8px;
  opacity: ${props => props.isEntering ? "0" : "1"};


  &::placeholder {
    color: #707070;
    opacity: 1; 
  }

  &:focus {
    outline:none;
  }
`;

export const LogoComponent = styled(Logo)`
  transform: scale(1.5);

  @media only screen and (max-width: 1024px) {
    transform: scale(1);
  }

  @media only screen and (max-width: 600px) {
    transform: scale(0.75);
  }
`;

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50vw;
  height: 50vh;

  input {
    width: 80%;
    height: 40px;
    margin-bottom: 8px;
    border-radius: 50px;
    padding-left: 24px;
    max-width: 450px;
    box-sizing: border-box;

    &::placeholder {
      color: #a6a6a6;
    }
  }

  button {
    height: 40px;
    margin-bottom: 8px;
    max-width: 450px;
    width: 40%;
    border-radius: 50px;
    background: #1b0d34;
    color: #fff;
    border: 1px solid #fff;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: #01f7e0;
      color: #1b0d34;
    }
    &:focus {
      opacity: 0.5;
    }
  }
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  button {
    height: max-content;
    padding: 15px;
    width: max-content;

    :first-child {
      margin-right: 10px;
    }
  }
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 32px;
  }
  h3 {
    color: #fff;
    margin-bottom: 8px;
  }
`;
