import styled from "styled-components";

export const AwaitingCall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AreaLead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 65%;

  p {
    margin-left: 10px;
  }
`;

export const TabbingArea = styled.div`
  width: 30%;

  button {
    margin-top: 20px;
    float: right;
  }
`;

export const AreaLeadTabbingStatus = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const AttendantsStopArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  div:first-child {
    width: 350px;
  }
`;
