export enum StatusConstants {
  CONNECTING = "Connecting",
  INACTIVE = "Inactive",
  ACTIVE = "Ativo",
  WAITING = "Waiting",
  PAUSED = "Paused",
  ATTENDANCE = "Attendance",
  TABBING = "Tabbing",
  ERROR = "Error"
}

export enum DayTimeConstants {
  MORNING,
  AFTERNOON,
  NIGHT,
}
