import { Store } from "pullstate";

interface IProps {
  children: React.ReactNode;
  opened: boolean;
  width?: string;
}

export const ModalStore = new Store<IProps>({
  children: undefined,
  opened: false,
  width: '50%',
});
