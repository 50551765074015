import styled from "styled-components";

export const Wrapper = styled.div`
  & form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & h3 { 
    margin-bottom: 16px;
  }

  & p {
    color: red;
    margin-top: 4px;
    font-size: 14px;
  }
`;

export const CustomizedInput = styled.input`
  width: 98%;  
  text-align: center;
  font-size: 18px;
`;

export const CustomizedButton = styled.button`
  cursor: pointer;
  width: 100%;
  color: #1F0533;
  background: #00FFE6;
  padding: 8px 0;
  margin-top: 16px;
  border: 0;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background: #00C7B3;
  }
`;