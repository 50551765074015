import styled from "styled-components";
import {ReactComponent as LogoffIcon} from "assets/icons/ico-logoff.svg";

export const NotificationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;

  & h1 {
    font-size: 20px;
    font-weight: 600;
    margin-right: 16px;
  }
`;

export const AvatarImage = styled.img`
  cursor: pointer;
  border-radius: 50%;
  background: #181a1b;
  z-index: 1;
`;

export const RoundedWhiteCircle = styled.div`
  position: absolute;
  width: 55px;
  height: 55px;
  background: #fff;
  right: -5px;
  border-radius: 50%;
`;

export const VerticalWhiteRetangle = styled.div`
  position: absolute;
  width: 55px;
  height: 100px;
  right: -5px;
  top: 0px;
  border-radius: 30% 30% 15px 15px;
  background: #fff;
`;

export const HorizontalWhiteRetangle = styled.div`
  position: absolute;
  width: 50%;
  height: 55px;
  right: -5px;
  bottom: -70px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-align: center;
  justify-content: center;
  padding: 8px;
`;

export const LogoffButton = styled.button`
  background: #F4F4F4;
  color: #707070;
  width: 100%;
  border-radius: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: none;
  cursor: pointer;
`;

export const IconLogoff = styled(LogoffIcon)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 4px;

  @media(max-width: 1024px) {
    display: none;
  }
`;

export const VersionContainer = styled.div`
  display: flex;
  margin-right: 4px;

  & p {
    font-size: 10px;
  }
`;
