const customSelectStyles = {
  option: (provided: any, state: { isSelected: any; }) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: '#000',
    padding: 8,
  }),
  control: () => ({
    width: '100%',
    background: '#fff',
    display: 'flex',
    color: 'white',
  }),
  placeholder: () => ({
    color: '#000',
    fontSize: '14px',
  }),
  singleValue: (provided: any, state: { isDisabled: any; }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

export default customSelectStyles;
