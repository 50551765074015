import React from 'react';
import { Wrapper } from './styles';

const MicVolume: React.FC = () => {
  return (
    <Wrapper>    
      <div id="micBar"></div>
    </Wrapper>
  );
}

export default MicVolume;
