import React from 'react';
import { useModal } from 'hooks';
import { Container, Wrapper } from './styles';
import { setModalOpened } from 'store/modal/actions';

interface IProps {
  children?: React.ReactNode;
  closeModalFunction?: Function;
  open?: boolean;
  width?: string;
}

const Modal: React.FC<IProps> = ({ closeModalFunction }: IProps) => {
  const { children, opened, width } = useModal();

  if (!opened) {
    return <></>;
  }

  return (
    <Wrapper onClick={() => setModalOpened(false)}>
      <Container width={width} onClick={(event) => event.stopPropagation()}>
        {children}
      </Container>
    </Wrapper>
  );
}

export default Modal;