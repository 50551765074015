import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clearLs } from 'localStorage';
import { Clock } from 'components/Clock';
import { StatusConstants } from 'utils/constants';
import { useCallHandler, useUser } from 'hooks';

import Avatar from "assets/images/avatar.png";

import { AvatarImage, 
         HorizontalWhiteRetangle, 
         IconLogoff, 
         LogoffButton, 
         NotificationsContainer, 
         RoundedWhiteCircle, 
         VersionContainer, 
         VerticalWhiteRetangle } from './styles';
import { logoffAttendant } from 'services/attendant';

interface IProps {
  showClock?: boolean;
}

const ProfileContainer: React.FC<IProps> = ({ showClock }: IProps) => {
  const route = useHistory();
  const { status } = useUser();
  const { ramal } = useCallHandler();
  const [clickedAvatar, setClickedAvatar] = useState(false);

  useEffect(() => {
    if (status === StatusConstants.ATTENDANCE) {
      setClickedAvatar(false);
    }
  }, [status]);

  const logoffHandler = async () => {
    try {
      await logoffAttendant();
      clearLs();
      route.push("/login");
    } catch {
      console.log("Algo deu errado ao fazer o logoff");
    }
  }

  return(
    <NotificationsContainer>
      <VersionContainer>
        <p>Versão:</p>
        <p>{process.env.REACT_APP_VERSION} |</p>
        <p>| Ramal:</p>
        <p>{ramal}</p>
      </VersionContainer>

      {showClock && <h1><Clock mask={false} /></h1>}
      {clickedAvatar && <>
      <RoundedWhiteCircle />
      <VerticalWhiteRetangle />
      <HorizontalWhiteRetangle>
        <LogoffButton onClick={() => logoffHandler()}>
          <IconLogoff />
          Fazer Logoff
        </LogoffButton>
      </ HorizontalWhiteRetangle></>}

      <AvatarImage 
        src={Avatar} 
        width={45} 
        height={45} 
        onClick={() => {
          if (status !== StatusConstants.ATTENDANCE) setClickedAvatar(clickedAvatar => !clickedAvatar);
        }}/>
    </NotificationsContainer>
  );
}

export default ProfileContainer;
