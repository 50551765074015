import { unPauseQueue } from "services/attendant";
import { setModalChildren, setModalOpened, setModalWidth } from "store/modal/actions";
import { setUserStatus } from "store/user/actions";
import { phoneMask, sleep } from "utils";
import { postponeCalculate, redialComplete } from "utils/calls";
import { StatusConstants } from "utils/constants";
import sweetAlert from "utils/sweetAlert";

export const schedulingPopup = async (schedulingResponse: any) => {
  await sweetAlert.confirm(
    `${schedulingResponse.client.name} | ${phoneMask(
      schedulingResponse.webhookCall.phone
    )} | ${schedulingResponse.tabbing.agDate} |
    ${schedulingResponse?.note}`,
    "Ligar agora",
    "Adiar",
    "Você tem uma ligação agendada em menos de cinco minutos",
    async () => {
      redialComplete(schedulingResponse);
    },
    async () => {
      await postponeCalculate(
        schedulingResponse
      );
      await sleep(3000);
      unPauseQueue();
      setUserStatus(StatusConstants.WAITING);
    }
  );
}

export const setActualModal = (component: React.ReactNode, opened: boolean, width='40%') => {
  setModalOpened(true);
  setModalChildren(component);
  setModalWidth(width);
}
