import React, { useEffect, useState } from 'react';
import { useUser } from 'hooks';
import { setAttendantPause } from '../helper';

import { IconPause, 
         SpecialActionButton, 
         SpecialActionButtonWhite, 
         SpecialActionContainer, 
         SpecialInputArea } from '../styles';
import { StatusConstants } from 'utils/constants';
import { setIsOnPauseQueue } from 'store/user/actions';

interface IProps {
  setClickedPause: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpecialPauseButton: React.FC<IProps> = ({ setClickedPause}: IProps) => {
  const { pauseStatus, status } = useUser();  
  const [pauseOption, setPauseOption] = useState("");

  useEffect(() => {
    if (pauseOption === "") return;

    if (status === StatusConstants.ATTENDANCE || status === StatusConstants.TABBING) {
      setIsOnPauseQueue(true, pauseOption);
      return;
    }
    setAttendantPause(pauseOption);
    setPauseOption("");
    setClickedPause(false);
  }, [pauseOption, setClickedPause, status]);

  const closePause = () => {
    setPauseOption("");
    setClickedPause(false);
  }

  return (
    <SpecialActionContainer>
      <SpecialInputArea>
        <select name="pauseOptions" id="pauseOptions" onChange={(event) => {
          setPauseOption(event.target.value);
        }}>
          <option value=""></option>
          {pauseStatus.map((status) => (<option value={status} key={status}>{status}</option>))}
        </select>
      </SpecialInputArea>
        <SpecialActionButton onClick={() => closePause()}>
          <IconPause blackfilled="true" />
        </SpecialActionButton>
      <SpecialActionButtonWhite />
    </SpecialActionContainer>
  );
}

export default SpecialPauseButton;
