import { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { useUser } from 'hooks';
import { StatusConstants } from 'utils/constants';
interface IClock {
  value?: string;
  onSetTimer?: (time: any) => void;
  paused?: boolean;
  isCallTiming?: boolean;
}

export const Timer = ({ value, onSetTimer, paused = false, isCallTiming = false }: IClock) => {
  const [timer, setTimer] = useState<string>("");
  const today = dayjs().format("YYYY-MM-DD");
  const { status } = useUser();

  const startTimer = useCallback(
    (value) => {
      const update =
        value.length <= 5
          ? dayjs(`${today} 00:${value}`).add(1, "second").format("mm:ss")
          : dayjs(`${today} ${value}`).add(1, "second").format("HH:mm:ss");
      if (!paused) setTimer(update);
      if (onSetTimer) onSetTimer(update);
    },
    [today, paused, onSetTimer]
  );
  const resetTiming = useCallback(() => {
    if (status === StatusConstants.ATTENDANCE) {
      setTimer("00:00")
    }
  }, [status])

  useEffect(() => {
    if(isCallTiming) {
      resetTiming()
    }
  }, [status])

  useEffect(() => {
    setTimer(value ? value : "00:00");
  }, [value]);

  useEffect(() => {
    const delay = setTimeout(() => {
      startTimer(timer);
    }, 1000);

    return () => clearTimeout(delay);
  }, [timer, startTimer]);

  return <>{timer}</>;
};
