import { ICall } from "interfaces/ICall";
import { Store } from "pullstate";

interface IProps {
  socket: any,
  callWithoutTabbing?: ICall,
  actualCall?: ICall,
  messageErrorLoginCaller: string,
  ramal: string,
  preApproachTabbinStatus: any,
}

export const SocketStore = new Store<IProps>({
  socket: null,
  callWithoutTabbing: undefined,
  actualCall: undefined,
  messageErrorLoginCaller: "",
  ramal: "",
  preApproachTabbinStatus: {},
});
