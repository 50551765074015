import styled from "styled-components";

export const Wrapper = styled.div`
  height: 35%;

  & h1 {
    margin-bottom: 16px;
    font-weight: 400;
    text-align: right;
  }
`;

export const Container = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #FFFFFF;
  height: 90%;

  & textarea {
    resize: none;
    background: none;
    height: 100%;
    font-size: 14px;
    outline: none;
    padding: 12px 12px;
    border: none;
    color: #FFFFFF;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: #FFFFFF;
      opacity: 0.6;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: flex-end;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(196,196,196, 0.3);
  padding: 6px 8px;
  border-radius: 5px;
  width: 18%;
  margin-left: 8px;
  border: none;
  cursor: pointer;

  & svg {
    transform: scale(0.75);
  }
`;
