import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

export const Container = styled.div<{ width?: string }>`
  width: ${props => props.width ? props.width : "50%"};
  height: auto;
  background: #fff;
  border-radius: 10px;
  color: #000;
  padding: 32px 32px;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

