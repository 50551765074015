import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const ActiveTime = styled.div`
  display: flex;
  align-items: center;

  & p {
    font-size: 30px;
    font-weight: 600;
    margin-left: 16px;
  }
`;

export const AudioContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & svg {
    margin-left: 16px;
    cursor: pointer;
  }
`;

export const FinishedCallPhrase = styled.p`
  color: #F80000;
  font-weight: 600;
  margin-top: 8px;
`;
