import { Timer } from 'components/Timer';
import React from 'react';
import { IconPause, TimerContainer, Wrapper } from './styles';

const PauseSection: React.FC = () => {
  return (
    <Wrapper>
      <TimerContainer>
        <p><Timer /></p>
      </TimerContainer>
      <IconPause />
    </Wrapper>
  );
}

export default PauseSection;
