import React, { useMemo } from 'react';
import { useUser } from 'hooks';
import { StatusConstants } from 'utils/constants';

import {ReactComponent as PauseIcon} from 'assets/icons/ico-pause.svg';
import {ReactComponent as PlayIcon} from 'assets/icons/ico-play.svg';
import {ReactComponent as RedBall} from 'assets/icons/ico-red-ball.svg';

import { StatusPhrase, TimersCell, TimersContainer, Wrapper } from './styles';

const Status: React.FC = () => {
  const { status, lastStatus } = useUser();

  const isActive = useMemo(() => 
    status === StatusConstants.ACTIVE || status === StatusConstants.WAITING ? true : false
  ,[status]);

  return (
    <Wrapper>
      <h4>Você está</h4>
      <StatusPhrase isActive={isActive}>
        {isActive
        ? 
        <>
          <h1>Ativo</h1>
          <PlayIcon style={{ transform: "scale(0.75)" }}/>
        </> 
        : 
        <>
          <h1>Inativo</h1>
          <RedBall style={{ transform: "scale(0.75)" }}/>
        </>
        } 
      </StatusPhrase>
      <TimersContainer>
        {lastStatus?.map((item, index) => (
          <TimersCell key={index}>
            <p>{item.hours}</p>
            {item.status === "IN_CALL" 
            ? <PlayIcon />
            : <PauseIcon />}
          </TimersCell>
        ))}
      </TimersContainer>
    </Wrapper>  
  );
}

export default Status;
