import React from 'react';
import { useCallHandler, useSocket, useUser } from 'hooks';
import { StatusConstants } from 'utils/constants';
import { calculateInitialPhrase } from 'utils';

import AttendanceSection from './AttendanceSection';
import TabbingSection from './TabbingSection';
import BottomMenu from './BottomMenu';
import PauseSection from './PauseSection';

import { LogoComponent, PresentationPhrase, Wrapper } from './styles';

const LeftPanel: React.FC = () => {
  const { status } = useUser();
  const { messageErrorLoginCaller } = useSocket();
  const { ramal } = useCallHandler();

  const showingLabel = () => {
    if (status === StatusConstants.ERROR) {
      return <PresentationPhrase><span>Desconectado</span></PresentationPhrase>;
    }
    if ((status === StatusConstants.CONNECTING && ramal === "")) {
        return <PresentationPhrase><span>Conectando...</span></PresentationPhrase>;
      }

    if (status === StatusConstants.WAITING && ramal && messageErrorLoginCaller === "") {
      return <PresentationPhrase><span>Aguardando chamada...</span></PresentationPhrase>;
    }
    if (status === StatusConstants.PAUSED) {
      return <></>
    }

    return <PresentationPhrase>{calculateInitialPhrase()}, <span>Joseph</span></PresentationPhrase>
  };

  const screenChooser = () => {
    if (status === StatusConstants.ATTENDANCE) return <AttendanceSection />;
    if (status === StatusConstants.TABBING) return <TabbingSection />;
    if (status === StatusConstants.PAUSED) {
      return (
        <>
          <PauseSection />
          <LogoComponent />
          {showingLabel()}
          <BottomMenu />
        </>
      );
    }

    return (
      <>
        <LogoComponent />
        {showingLabel()}
        <BottomMenu />
      </>
    )

  };

  return (
    <Wrapper>
      {screenChooser()}
    </Wrapper>
  );
}

export default LeftPanel;
