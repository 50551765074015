import React from 'react';
import { ActiveBorder, ChartIcon, Container, HouseIcon, MenuCell, SuggestionIcon, Wrapper } from './styles';

const BottomMenu: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <MenuCell>
          <HouseIcon />
          <p>Inicial</p>
          <ActiveBorder />
        </MenuCell>
        <MenuCell>
          <ChartIcon />
          <p>Desempenho</p>
        </MenuCell>
        <MenuCell>
          <SuggestionIcon />
          <p>Sugestões</p>
        </MenuCell>
      </Container>
    </Wrapper>
  );
}

export default BottomMenu;
