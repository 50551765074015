import dayjs from 'dayjs';
import { useUser } from 'hooks';
import { ICall } from 'interfaces/ICall';
import React from 'react';
import { CallContainer, CallsCell, Wrapper } from './styles';

const LastCalls: React.FC = () => {
  const { lastCalls } = useUser();
  
  return (
    <Wrapper>
      <h1>Ultimas ligações</h1>
      <CallContainer>
      {lastCalls.slice(0, 5).map((callItem: ICall, index) => {
       const date = dayjs(callItem.createdAt);

       return (
        <CallsCell key={index}>
          <p>{date.date()}/{date.month()}/{date.year()} - {date.hour()}:{date.minute()}</p>
        </CallsCell>
       )})}
      </CallContainer>
    </Wrapper>
  );
}

export default LastCalls;
