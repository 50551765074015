import styled from "styled-components";

export const Wrapper = styled.div`
  & h1 {
    text-align: right;
    margin-top: 32px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const AnotationContainer = styled.div`
  width: 100%;
  padding: 8px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin-top: 16px;
  text-align: center;
  cursor: pointer;
`;
