import styled from "styled-components";
import {ReactComponent as EyeOpen} from "assets/icons/eyeOpen.svg";
import {ReactComponent as EyeClosed} from "assets/icons/eyeClosed.svg";

export const InputFormPasswordWrapper = styled.div<{isEntering: boolean}>`
  position: relative;
  display: flex;;
  align-items: center;
  border: 1px solid #b1b1b1;
  border-radius: 30px;
  justify-content: space-around;
  padding: 8px 0;
  width: 60%;
  margin-top: 8px;
  opacity: ${props => props.isEntering ? "0" : "1"};
`;

export const InputFormPassword = styled.input`
  text-align: center;
  border: none;

  &::placeholder {
    color: #707070;
    opacity: 1; 
  }

  &:focus {
    outline:none;
  }
`;

export const ButtonIconEye = styled.button`
  position: absolute;
  right: 16px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;

export const ButtonEyeOpen = styled(EyeOpen)`
`;

export const ButtonEyeClosed = styled(EyeClosed)`
`;
