import styled from "styled-components";
import {ReactComponent as PauseIcon} from 'assets/icons/ico-pause.svg';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  & h1 {
    font-size: 96px;
    padding: 0;
    line-height: 0;
  }
`;  

export const TimerContainer = styled.div`
  font-size: 96px;
  margin-right: 64px;
`;

export const IconPause = styled(PauseIcon)`
  position: relative;
  width: 80px;
  height: 80px;
`;
