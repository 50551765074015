import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #373737;
  border-radius: 6px;

  #micBar {
    width: 1%;
    height: 8px;
    background-color: #00FFE6;
    float: right;
    border-radius: 6px;
  }
`;
