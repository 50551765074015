import { ICall } from "interfaces/ICall";
import { makeRedialCall, postponeCall } from "services/attendant";
import { setUserStatus } from "store/user/actions";
import { StatusConstants } from "utils/constants";
import sweetAlert from "utils/sweetAlert";

export const postponeCalculate = async (
  callData: ICall,
  ) => {

  await postponeCall(callData);
}

export const redialComplete = async (callData: ICall) => {
  try {
    const tabbingStatus = {
      type: "rediscar"
    }

    await makeRedialCall(tabbingStatus, callData, "");
    setUserStatus(StatusConstants.ATTENDANCE);
  } catch {
    sweetAlert.error("Ocorreu um erro ao rediscar");
  }
}
