import styled from "styled-components";
import {ReactComponent as PlayIcon} from 'assets/icons/ico-play.svg';
import {ReactComponent as PauseIcon} from 'assets/icons/ico-pause.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;

  & h2 {
    font-weight: bold;
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  padding: 16px 32px;
  border: 2px solid white;
  border-radius: 10px;
  fill: white !important;
  background: none;
`;

export const SpecialActionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpecialInputArea = styled.div`
  position: absolute;
  background: #fff;
  width: 300%;
  top: -40px;
  left: -210%;
  color: black;
  padding: 8px;
  border-radius: 15px 15px 0 15px;

  & select {
    width: 100%;
    border: 1px solid rgba(112, 112, 112, 40);
    border-radius: 10px;
    font-size: 14px;
  }

  @media(max-width: 1024px) {
    width: 200%;
    left: -110%;
  }
`;

export const SpecialActionButton = styled.div`
  cursor: pointer;
  padding: 16px 32px;
  border: 2px solid white;
  border-radius: 10px;
  fill: black !important;
  position: relative;
  border: 2px solid #00FFE6;
  z-index: 2;
`;

export const SpecialActionButtonWhite = styled.div`
  position: absolute;
  background: white;
  width: 120%;
  height: 120%;
  border-radius: 0 0 15px 15px;
`;

export const IconPlay = styled(PlayIcon)`
  transform: scale(1.5);

  & path {
    opacity: 1;
  }
`;

export const IconPause = styled(PauseIcon)<{ blackfilled?: string }>`
  transform: scale(1.5);
  z-index: 2;

  & path {
    opacity: 1;
    fill: ${props => props.blackfilled === "true" ? "black" : "white"};
  }

  & g {
    opacity: 1;
  }
`;
