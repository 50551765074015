import React from 'react';
import { setShowingNotes } from 'store/user/actions';
import { AnotationContainer, Separator, Wrapper } from './styles';

const LastAnotations: React.FC = () => {
  return (
    <Wrapper>
      <Separator />
      <h1>Minhas anotações</h1>
      <AnotationContainer onClick={() => setShowingNotes(true)}>
        Expandir
      </AnotationContainer>
    </Wrapper>
  );
}

export default LastAnotations;
