import React, { useEffect } from 'react';
import { useUser } from 'hooks';
import { StatusConstants } from 'utils/constants';
import { updateLastCalls, updateLastStatus, updatePauseStatus } from './helper';

import PlayContainer from './PlayContainer';
import AttendanceSection from './AttendanceSection';
import IndexSection from './IndexSection';

import { Wrapper } from './styles';

const RightPanel: React.FC = () => {
  const { status, lastCalls } = useUser();
  
  useEffect(() => { 
    updateLastCalls();
    updatePauseStatus();
    updateLastStatus();
  }, []);

  if (lastCalls.length === 0) {
    <Wrapper />
  }

  return (
    <Wrapper>
      {status === StatusConstants.ATTENDANCE || status === StatusConstants.TABBING
      ? <AttendanceSection />
      : <IndexSection />}
      <PlayContainer />
    </Wrapper>
  );
}

export default RightPanel;
