import React from 'react';
import { useUser } from 'hooks';

import { Clock } from 'components/Clock';
import LastAnotations from '../LastAnotations';
import LastCalls from '../LastCalls';
import ProfileContainer from '../ProfileContainer';
import Status from '../Status';
import { ClockContainer } from '../styles';
import AnotationContainer from '../AnotationContainer';

const IndexSection: React.FC = () => {
  const { showingNotes } = useUser();  

  return (
    <>
      <ProfileContainer />
      <ClockContainer>
        <Clock mask={true} />
      </ClockContainer>
      <Status />
      {!showingNotes && <LastCalls />}
      {showingNotes 
      ? <AnotationContainer />
      : <LastAnotations />}
    </>
  );
}

export default IndexSection;
